// Variables
$gs-primary: #122649; // Blue
$gs-secondary: #f0b41c; // Yellow
$default-background: #f5f5f5; // Grey
$darken-default-background: darken($default-background, 40%); // Grey
// https://mui.com/material-ui/customization/palette/
$mui-success-main: #2e7d32; // Green
$mui-success-alert-bg: #edf7ed; // pale success green
$mui-error-light: #ef5350;
$app-box-bg: white;
$app-box-padding: 24px;
$app-box-padding-mobile: 16px;
$app-breadcrumb-space: 37px;

$break-point-tablet-portrait: 600px;
$break-point-tablet-landscape: 900px;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // from amplify package we removed:
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  min-height: 100vh;
}

// from amplify package that we removed
* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

[data-amplify-authenticator] {
  display: none;
  [data-amplify-container] {
    [data-amplify-header] {
      text-align: center;
      margin-top: 100px;
    }
    [data-amplify-footer] {
      text-align: center;
      font-size: 80%;
    }
  }
}

.App-content {
  background-color: $default-background;
  min-height: 100vh;
  padding: 15px;
}

.App-box,
.App-panel-horizontal {
  background-color: $app-box-bg;
  padding: $app-box-padding;
  height: calc(100vh - #{$app-breadcrumb-space} - (#{$app-box-padding} * 4));
  overflow-x: auto;
  overflow-y: auto;
  white-space: normal;
  transition: border 300ms ease-out;
  position: relative;
  &.active {
    border: 1px $gs-primary solid;
    padding: $app-box-padding - 1;
  }

  &.App-box-horizontal {
    height: inherit;
  }

  h1 {
    margin-top: 0px;
    margin-bottom: 0.83em;
    font-size: 1.5em;
  }

  * ~ h1 {
    margin-top: unset;
    margin-bottom: unset;
    font-size: unset;
  }

  h2 {
    margin-top: 0px;
    .highlight {
      color: $gs-primary;
    }
  }
  * ~ h2 {
    // h2 not at the top of .app-box
    margin-top: 16px;
  }
  h2 ~ .sub-title,
  div.h3 ~ .sub-title {
    color: $darken-default-background;
    margin-top: -20px;
    span {
      margin-right: 20px;
      font-size: 90%;
    }
    border-bottom: 1px solid $darken-default-background;
  }

  .bottom-button {
    position: absolute;
    bottom: $app-box-padding;
  }
  .custom-label {
    font-weight: bold;
    min-width: 100px;
    margin-right: 16px;
  }
  .custom-link {
    text-decoration: none;
    color: $gs-primary;
    &:hover {
      color: darken($gs-primary, 20%);
    }
    &:visited,
    &:active {
      color: $gs-primary;
    }
  }
}

.App-box.horizontal {
  border-bottom: 1px solid $gs-primary;
  padding: 0px;
}

.App-panel-horizontal {
  border-bottom: 1px solid $gs-primary;
  min-height: unset;
}

.Dash-box {
  padding: 0px !important;
  border-radius: 3px;
  h3 {
    margin-top: 0;
    padding: 5px;
    // border-bottom: 1px solid $gs-primary;
    // background-color: lighten(#f0b41c, 20%);
    background-color: $default-background;
  }
}

.uppercase {
  text-transform: uppercase;
}

nav.breadcrumb {
  margin-bottom: 10px;
  line-height: 27px;
  li {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 200px;
    // margin-bottom: 5px;
  }
  a {
    text-decoration: none;
    cursor: pointer;
    svg.MuiChip-icon {
      margin-right: 6px;
    }
    span:empty {
      cursor: pointer;
      display: none;
    }
  }
}

pre.api-response,
div.api-response {
  white-space: pre-wrap;
  overflow: auto;
  max-height: 300px;
  padding: 5px;
}

form small.error {
  color: $mui-error-light;
}

@media (max-width: $break-point-tablet-portrait) {
  // 600 px
  .App-box {
    padding: $app-box-padding-mobile;
  }
}
@media (max-width: $break-point-tablet-landscape) {
  // 900 px
  .App-box {
    min-height: inherit;
    height: inherit;
    .bottom-button {
      position: inherit;
      bottom: auto;
      margin-top: 16px;
    }
  }
}
